<template>
  <GuideStep v-if="reportStore.entityDetail?.guideStep < 1" type="TRANSACTIONS" />
  <template v-else>
    <div>
      <Header
        ref="transactionsHeaderRef"
        type="TRANSACTIONS"
        :title="t('project.nav.transactions')"
        :updated-on="updatedOn"
        :show-regenerate="true"
        :show-regenerate-button="true"
      />

      <HeaderTabs :duplicate-total="duplicateTotal" :review-total="reviewTotal" />

      <TransactionScreening table-type="reviewed" @onCheckBatchTransactions="onCheckBatchTransactions" />
    </div>
    <ProcessTable ref="processTableRef" type="categorized" :height="tableHeight" />
  </template>
</template>
<script lang="ts" setup>
import { find } from 'lodash-es'
import utils from '@/lib/utils'
import { useI18n } from 'vue-i18n'
import Header from '../components/Header.vue'
import GuideStep from '../components/GuideStep.vue'
import HeaderTabs from './components/HeaderTabs.vue'
import { useGlobalStore } from '@/stores/modules/global'
import ProcessTable from '../components/ProcessTable.vue'
import { useReportStore } from '@/stores/modules/reports/index'
import TransactionScreening from './components/Screening.vue'
import { useReportsTableConfigStore } from '@/stores/modules/reports/tableConfig'

const { t } = useI18n()
const route = useRoute()
const reportStore = useReportStore()
const globalStore = useGlobalStore()
const tableConfigStore = useReportsTableConfigStore()
const processTableRef = ref()
const transactionsHeaderRef = ref()
const loading = ref(false)

const updatedOn = computed(() => {
  const dateTime = processTableRef.value?.groupLitsData?.listData?.fromDate
    ? `From ${utils.dateFormatting(
        processTableRef.value?.groupLitsData?.listData?.fromDate,
        'date'
      )} to ${utils.dateFormatting(processTableRef.value?.groupLitsData?.listData?.toDate, 'date')}`
    : ''
  return dateTime
})

const duplicateTotal = computed(() => {
  return find(reportStore.issueList, { type: 'DUPLICATE' })?.count ?? 0
})

const reviewTotal = computed(() => {
  return find(reportStore.issueList, { type: 'FOR_REVIEW' })?.count ?? 0
})

const tableHeight = computed(() => {
  if (globalStore.showUpGradeInfo) {
    return transactionsHeaderRef.value?.isShowRegeneratedInfo ? `calc(100vh - 281px)` : `calc(100vh - 233px)`
  }
  return transactionsHeaderRef.value?.isShowRegeneratedInfo ? `calc(100vh - 250px)` : `calc(100vh - 202px)`
})

const entityId = computed(() => {
  return Number(route.params?.entityId)
})

const onCheckBatchTransactions = () => {
  processTableRef.value.onCheckBatchTransactions()
}

const onResize = () => {
  reportStore.agGridApi?.sizeColumnsToFit({ columnLimits: [{ key: 'transactions-from-to', minWidth: 263 }] })
}
watch(
  route,
  async (newValue) => {
    try {
      if (newValue.name === 'reports-transactions-categorized') {
        loading.value = true
        reportStore.transactionFilter = {}
        reportStore.checkTransactionsAll = false
        reportStore.batchEditSelected = []
        tableConfigStore.processTableConfig = []
        await reportStore.fetchActivityEventList(entityId.value, 'REVIEWED')
        reportStore.transactionFilter.categoryNos = reportStore.activityEventList
        await tableConfigStore.getProcessTableConfig('transactions')
        await reportStore.transactionsFilterDataInit(entityId.value)
        setTimeout(() => {
          loading.value = false
        }, 300)
      }
    } catch (error) {
      loading.value = false
    }
  },
  { immediate: true }
)

onMounted(() => {
  window.addEventListener('resize', onResize)
})
onBeforeUnmount(() => {
  reportStore.batchReviewParams = {}
  window.removeEventListener('resize', onResize)
})
</script>
<style lang="scss" scoped></style>
